import type { IPictureElementList } from '../PictureElementList'
import { useEffect, useRef } from 'react'
import { SVGIcon } from '../../atoms/SVGIcon'
import { PictureElementList } from '../PictureElementList'
import { theme } from '../../../styles'

export type IPictureFullScreen = {
  setHasFullScreen: (hasFullScreen: boolean) => void
  pictureRef?: { current: any }
} & IPictureElementList

export function PictureFullScreen({ setHasFullScreen, pictureRef, ...props }: IPictureFullScreen) {
  const PictureFullScreenRef = useRef(null)
  const handleOnClick = () => {
    setHasFullScreen(false)
    //Accessibility: Focus on element who has open the modal
    pictureRef?.current?.focus?.({ preventScroll: false })
    document.removeEventListener('keydown', handleKeydown)
  }

  //Accessibility: Block Tab in modal
  const handleKeydown = (e) => {
    if (PictureFullScreenRef.current) {
      const children = Array.from([...PictureFullScreenRef.current.children])
      const firstFocusableElement = children[0]
      const lastFocusableElement = children[children.length - 1]
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9
      const isEscapePressed = e.key === 'Escape' || e.keyCode === 27

      if (isEscapePressed) {
        handleOnClick()
        return
      }

      if (!isTabPressed) {
        return
      }

      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus({ preventScroll: false }) // add focus for the last focusable element
          e.preventDefault()
        }
      } else {
        // if tab key is pressed
        if (
          document.activeElement === lastFocusableElement ||
          !children.includes(document.activeElement)
        ) {
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus({ preventScroll: false }) // add focus for the first focusable element
          e.preventDefault()
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  return (
    <>
      <div className="PictureFullScreen" ref={PictureFullScreenRef}>
        <button
          className="PictureFullScreen__Close"
          onClick={handleOnClick}
          title="Fermer le plein écran"
        >
          <SVGIcon name="close" size={32} primaryColor={theme.cssVars.deepBlue} />
        </button>
        <label htmlFor="zoom">
          <input type="checkbox" id="zoom" />
          <PictureElementList {...props} />
        </label>
      </div>
      <style jsx>{`
        .PictureFullScreen {
          position: absolute;
          display: block;
          background-color: ${theme.cssVars.darkestBlue};
          transition: background-color 300ms;
          height: 100%;
          width: 100%;
          z-index: 65;
          top: 0;
          overflow: auto;
        }

        .PictureFullScreen input[type='checkbox'] {
          display: none;
        }

        .PictureFullScreen :global(input[type='checkbox']:checked ~ picture img) {
          transform: scale(2);
          cursor: zoom-out;
        }

        :global(#body-header),
        :global(.PageTemplate__Main),
        :global(.p3-outlet) {
          display: none !important;
        }

        .PictureFullScreen :global(img) {
          display: block;
          -webkit-user-select: none;
          margin: auto;
          cursor: zoom-in;
          background-color: ${theme.cssVars.darkestBlue};
          transition: background-color 300ms;
          transition: transform 0.25s ease;
          width: 100%;
          object-fit: contain;
          max-width: 100%;
          height: 100%;
        }

        .PictureFullScreen__Close {
          position: fixed;
          right: 15px;
          top: 15px;
          z-index: 1;
          background-color: ${theme.cssVars.white};
          border-radius: 50%;
          border: hidden;
          width: 40px;
          height: 40px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </>
  )
}
